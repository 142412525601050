import { title } from "assets/jss/material-kit-react.jsx";

const blogSectionStyle = {
  section: {
    padding: "20px 20px 0 20px",
  },
  title: {
    ...title,
    borderTop: "solid black 1px",
    marginBottom: "5px",
    marginTop: "20px",
    minHeight: "25px",
    textDecoration: "none",
  },
  description: {
    color: "black",
  },
  date: {

  },
  link: {
    fontWeight: 'bolder',
    color: 'black',
    '&:hover': {
      color: "#0000ee",
    }
  },
  textCenter: {
    // had justify items here
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  }
};


export default blogSectionStyle;