import React from "react";
import { Link } from 'gatsby';


import { graphql, useStaticQuery, StaticQuery} from 'gatsby';
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import blogSectionStyle from "assets/jss/material-kit-react/views/landingPageSections/blogSectionStyle.jsx";

class BlogSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div  >
        <GridContainer justify="center" className={classes.description}>
          <GridItem >
          <StaticQuery 
                query={graphql`
                    query {
                        allMarkdownRemark (sort: {fields: [frontmatter___date], order: DESC}) {
                            edges{
                                node{
                                    frontmatter{
                                        title
                                        date (formatString: "MMM DD, YYYY")
                                    }
                                    fields{
                                        slug
                                    }
                                    excerpt (pruneLength: 750)
                                }
                            }
                        }
                    }
                `}
                render={data => (
                    <div>
                            {data.allMarkdownRemark.edges.map((edge) => {
                                return(
                                    <div className={classes.section}>
                                        <h2 className={classes.title}>{edge.node.frontmatter.title}</h2>
                                        <h4>{edge.node.frontmatter.date}</h4>
                                        <p className={classes.subtitle}>{edge.node.excerpt}</p>
                                        
                                            <Link 
                                                to={`/blog-page/${edge.node.fields.slug}`}
                                                className={classes.link}
                                            >
                                                Read more...
                                            </Link>
                                    </div>                                    
                                )
                            })}
                    </div>
                )}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(blogSectionStyle)(BlogSection);

